<template>
  <div>
    <b-row class="align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            id="keyword"
            v-model="quickSearch.keyword"
            placeholder="Müşteri Adı, Firma Adı, Telefon, Plaka..."
            @keydown.enter="searchAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchAction"
            ><FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="filterData.detailSearch"
          name="check-button"
          style="padding-top: 5px"
          switch
          inline
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-button
          :href="downloadExcel"
          target="_blank"
          variant="success"
          class="mr-1"
        >
          <FeatherIcon icon="FileTextIcon" />
          Excel Aktar
        </b-button>
        <b-button
          to="service/search"
          variant="primary"
        >
          <FeatherIcon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="filterData.detailSearch"
      class="mt-1"
    >
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :close-on-select="false"
            :reduce="brand => brand.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Durum"
          label-for="id_com_service_status"
        >
          <v-select
            id="id_com_service_status"
            v-model="filterData.id_com_service_status"
            placeholder="Durum"
            :options="serviceStatuses"
            label="title"
            :close-on-select="false"
            :reduce="item => item.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Lokasyon"
          label-for="id_com_location"
        >
          <v-select
            id="id_com_location"
            v-model="filterData.id_com_location"
            placeholder="Lokasyon"
            :options="locations"
            label="title"
            :close-on-select="false"
            :reduce="item => item.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Servis Danışmanı"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="filterData.id_com_user"
            placeholder="Servis Danışmanı"
            :options="users"
            label="title"
            :close-on-select="false"
            :reduce="item => item.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="XIcon" />
          Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RequestFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
    actionFilter: {
      type: Function,
      required: true,
    },
    downloadExcel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      keyword: null,
      detailSearch: false,
      config: {
        mode: 'range',
        locale: 'tr',
        dateFormat: 'd.m.Y',
      },
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['serviceTracing/getFilterData']
    },
    quickSearch() {
      return this.$store.getters['serviceTracing/getQuickSearch']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    serviceStatuses() {
      return this.$store.getters['serviceStatuses/getServiceStatuses']
    },
    serviceTypes() {
      return this.$store.getters['serviceTypes/getServiceTypes']
    },
  },
  created() {
    this.getBrands()
    this.getLocations()
    this.getUsers()
    this.getServiceStatuses()
    this.getServiceTypes()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 3,
        },
      })
    },
    getServiceStatuses() {
      this.$store.dispatch('serviceStatuses/serviceStatusesList')
    },
    getServiceTypes() {
      this.$store.dispatch('serviceTypes/serviceTypesList')
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter()
    },
    resetFilter() {
      this.$store.commit('serviceTracing/RESET_FILTER_DATA')
      this.filterAction()
    },
    resetquickSearch() {
      this.quickSearch.keyword = ''
      this.filterAction()
    },
  },
}
</script>
