<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(customer)="data">
        <div class="font-small-2">
          {{ data.item.customer }}
        </div>
        <div class="text-muted font-small-1">
          <small>{{ data.item.company }}</small>
        </div>
        <div class="font-small-2">
          {{ data.item.car_brand }} {{ data.item.car_model }} {{ data.item.myear }}
          <div class="text-danger">
            {{ data.item.license_plate }}
          </div>
        </div>
        <div
          v-for="(type,key) in data.item.service_type"
          :key="key"
        >
          <b-badge
            variant="primary"
            class="font-small-1 mr-1"
          >
            {{ type }}
          </b-badge>
        </div>
      </template>
      <template #cell(appointment_date)="data">
        <div class="font-small-2 font-weight-bold text-nowrap">
          {{ moment(data.item.appointment_date).format('ll') }} {{ data.item.appointment_time }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.location }}
        </div>
      </template>
      <template #cell(service_status)="data">
        <div>
          <b-badge :variant="data.item.styleclass === 'default' ? '' : data.item.styleclass">
            {{ data.item.service_status }}
          </b-badge>
        </div>
      </template>
      <template #cell(username)="data">
        <div class="font-small-2 font-weight-bold">
          {{ data.item.username }}
        </div>
        <div class="font-small-2 text-muted">
          {{ data.item.meet }}
        </div>
      </template>
      <template #cell(control)="data">
        <b-dropdown
          :split-to="'/service/view/' + data.item.id"
          text="Görüntüle"
          variant="outline-primary"
          size="sm"
          split
        >
          <b-dropdown-item :to="'/service/edit/' + data.item.id">
            Güncelle
          </b-dropdown-item>
          <!--
          <b-dropdown-item @click="removeData(data.item.id)">
            Sil
          </b-dropdown-item>
          -->
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  name: 'Table',
  components: {
    BTable,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    removeData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '7', '8'],
      fields: [
        {
          key: 'customer',
          label: 'Müşteri Adı',
          thClass: 'text-nowrap text-left',
          tdClass: 'text-nowrap text-left',
        },
        {
          key: 'appointment_date',
          label: 'Randevu',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
          thClass: 'text-nowrap text-left width-100',
          tdClass: 'text-nowrap text-left font-small-2',
        },
        {
          key: 'service_status',
          label: 'Durum',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
          thClass: 'text-nowrap text-center width-100',
          tdClass: 'text-nowrap text-center font-small-2',
        },
        {
          key: 'username',
          label: 'Danışman',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceTracing/getServices']
    },
  },
}
</script>
