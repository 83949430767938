<template>
  <b-card-body>
    <b-row v-if="counters">
      <b-col>
        <item-statistics
          title="Beklemede"
          :value="counters[1]"
          color="light-warning"
          icon="ClockIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="İşlem Yapılıyor"
          :value="counters[2]"
          color="light-info"
          icon="EyeIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Gelmedi"
          :value="counters[4]"
          color="light-danger"
          icon="FlagIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="İşlem Tamamlandı"
          :value="counters[3]"
          color="light-success"
          icon="CheckCircleIcon"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
} from 'bootstrap-vue'

import ItemStatistics from '@/views/Service/components/ItemStatistics.vue'

export default {
  name: 'Counters',
  components: {
    BCardBody,
    BRow,
    BCol,
    ItemStatistics,
  },
  computed: {
    counters() {
      return this.$store.getters['serviceTracing/getCounters']
    },
  },
}
</script>

<style scoped>

</style>
